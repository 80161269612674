import React from "react"
import PropTypes from "prop-types"
import LazyLoad from "react-lazyload"

// ---------------------------------------------------------

import Button from "@components/button"
import Card from "@components/card"
import Carousel from "@components/carousel"

// ---------------------------------------------------------

import {
  container,
  recommend_container,
  recommend_title,
  button_container
} from "./styles.module.scss"

// ---------------------------------------------------------

export const getContentUrl = (slug, type, pagePath) => {
  switch (type) {
    case "Page":
      return `${pagePath}/${slug}`
    case "BlogPost":
      return `/blog/${slug}`
    case "CompanyNews":
      return `/announcements/${slug}`
    case "CustomEvent":
      return `/events/${slug}`
    case "DemoPage":
      return `/demo/${slug}`
    case "Event":
      return `/events/${slug}`
    case "NewsPost":
      return `${slug}`
    case "Award":
      return `${slug}`
    case "Resource":
      return `/resources/${slug}`
    case "WebinarResource":
      return `/resources/webinars/${slug}`
  }
}

// ---------------------------------------------------------

const RecommendedReading = ({ recommended, title, buttonLabel, buttonUrl }) => {
  if (!recommended) return <></>

  const pageMap = {
    "/platform": "Platform",
    "/why-amperity": "Platform",
    "/capability": "Capability",
    "/partners": "Partner",
    "/customers": "Customer Story",
    "/solutions/departments": "Solution",
    "/solutions/industries": "Solution",
    "/solutions/use-cases": "Solution"
  }

  return (
    <LazyLoad className={container} offset={300} once>
      <div className={recommend_container}>
        <h3 className={recommend_title}>{title}</h3>
        <Carousel uniqueId="recommended" contentType="card">
          {recommended &&
            recommended.map((card, idx) => {
              const contentType = {
                Award: "Award",
                BlogPost: "Blog",
                CompanyNews: "Announcement",
                CustomEvent: "Event",
                DemoPage: "Demo",
                Event: "Event",
                NewsPost: "Media feature",
                Page: pageMap[card.pagePath] || "Page",
                Resource: card.resourceType,
                WebinarResource: "Webinar"
              }

              const slug = card.slug || card.link

              return (
                <Card
                  theme="hover"
                  label={contentType[card.__typename]}
                  key={idx}
                  title={card.title}
                  description={card.excerpt}
                  image={card.featuredImage || card.image}
                  startDate={card.date || card.startDate}
                  buttonUrl={getContentUrl(slug, card.__typename, card.pagePath)}
                />
              )
            })}
        </Carousel>
        {buttonUrl && buttonLabel ? (
          <div className={button_container}>
            <Button theme="inverted" url={buttonUrl}>
              {buttonLabel}
            </Button>
          </div>
        ) : null}
      </div>
    </LazyLoad>
  )
}

RecommendedReading.propTypes = {
  /**
   * Recommended items to be displayed in the component
   */
  recommended: PropTypes.array,
  /**
   * Header above the recommended items
   */
  title: PropTypes.string,
  /**
   * Label for the redirect button at the right bottom of the component
   */
  buttonLabel: PropTypes.string,
  /**
   * Redirect url for the button at the right bottom of the component
   */
  buttonUrl: PropTypes.string
}

RecommendedReading.defaultProps = {
  title: "Recommended Content"
}

export default RecommendedReading
